// Imports
import Alpine from 'alpinejs';

// Project imports
import PageScrolling from '@js/modules/PageScrolling';

class Navigation {
    constructor() {
        this.nav = document.getElementById('mobile-nav');

        // Set up Alpine store to control nav toggle
        Alpine.store('nav', {
            isOpen: false,
            subOpen: null,

            toggle() {
                if (this.isOpen) {
                    this.close();
                }
                else {
                    this.open();
                }
            },
            open() {
                // Open nav
                this.isOpen = true;

                // Disable scroll
                PageScrolling.lock(this.nav);
            },
            close() {
                // Close nav
                this.isOpen = false;
                // Close sub nav
                this.subOpen = null;

                // Enable scroll
                PageScrolling.unlock(this.nav);
            }
        });
    }
}

export default new Navigation();
