// Imports
import SplitType from 'split-type';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

ScrollTrigger.clearScrollMemory('manual');

class Earth {
    constructor() {
        this.containerContent = document.querySelector('.earth__container-content');
        this.scrubSpeed = 1;
    }

    init() {
        // If no container content, do nothing
        if (!this.containerContent) {
            return;
        }

        // Explore button action
        // document.querySelector('.explore-btn').addEventListener('click', () => {
        //     gsap.to(window, {
        //         duration: window.REGENERATION.env.reducedMotion ? 0 : 8,
        //         scrollTo: '#home-content'
        //     });
        // });

        // Earth starting animation
        gsap.fromTo(
            '.explore-btn',
            {
                opacity: 0,
            },
            {
                opacity: 1,
                duration: 0.6,
            },
        );

        let earth = gsap.timeline({
            scrollTrigger: {
                trigger: this.containerContent,
                start: 'top top',
                end: 'bottom bottom',
                scrub: this.scrubSpeed,
            },
        });

        // Parallax scroll for the .earth__fixed element
        gsap.to('.earth__fixed', {
            yPercent: -105,
            ease: 'none',
            scrollTrigger: {
                trigger: this.containerContent,
                start: 'bottom bottom',
                end: 'bottom top-=500px',
                scrub: true,
            }
        });

        // Disable pointer-events on the .earth element when bottom of .earth__container-content reaches the top of the viewport
        ScrollTrigger.create({
            trigger: this.containerContent,
            start: 'bottom top',
            onUpdate: (self) => {
                if (self.progress === 1) {
                    gsap.set('.earth', {
                        opacity: 0,
                        pointerEvents: 'none',
                    });
                } else {
                    gsap.set('.earth', {
                        opacity: 1,
                        pointerEvents: 'auto',
                    });
                }
            }
        });

        earth.to(
            '.explore-btn',
            0.1,
            {
                opacity: 0,
                pointerEvents: 'all',
            },
            0,
        );

        earth.to(
            '.explore-btn',
            0.1,
            {
                pointerEvents: 'none',
            },
            0.1,
        );

        earth.fromTo(
            '.earth__fixed-image',
            0.1,
            {
                scale: 3.75,
            },
            {
                scale: 2.65,
            },
            0,
        );

        earth.fromTo(
            '.earth__wrap-black',
            0.1,
            {
                scale: 3.75,
            },
            {
                scale: 2.65,
            },
            0,
        );

        earth.fromTo(
            '.earth__wrap-names',
            0.1,
            {
                scale: 3.75,
            },
            {
                scale: 2.65,
            },
            0,
        );

        earth.to(
            '.earth__fixed-image',
            0.5,
            {
                scale: 1,
            },
            0.1,
        );

        earth.to(
            '.earth__wrap-black',
            0.5,
            {
                scale: 1,
            },
            0.1,
        );

        earth.to(
            '.earth__wrap-names',
            0.1,
            {
                scale: 1.3,
            },
            0.1,
        );

        earth.fromTo(
            '.earth__wrap-black',
            0.1,
            {
                opacity: 0.1,
            },
            {
                opacity: 0.5,
            },
            0.1,
        );

        earth.to(
            '.earth__wrap-lines .earth__circle',
            0.1,
            {
                clipPath: 'circle(50% at 50% 50%)',
            },
            0.28,
        );

        earth.to(
            '.earth__wrap-black',
            0.1,
            {
                opacity: 0,
            },
            0.3,
        );

        earth.fromTo(
            '.earth__wrap-names',
            0.1,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                scale: 1.4,
            },
            0.25,
        );

        earth.to(
            '.earth__wrap-names',
            0.25,
            {
                scale: 1,
            },
            0.35,
        );

        // Appearance of black and white sections
        earth.fromTo(
            '.earth__wrap-gray .earth__circle:nth-child(2)',
            0.2,
            {
                clipPath: 'circle(0%)',
            },
            {
                clipPath: 'circle(50%)',
                ease: 'none'
            },
            0.375,
        );

        earth.fromTo(
            '.earth__wrap-gray .earth__circle:nth-child(3)',
            0.2,
            {
                clipPath: 'circle(0%)',
            },
            {
                clipPath: 'circle(30%)',
                ease: 'none'
            },
            0.375,
        );

        earth.fromTo(
            '.earth__wrap-gray .earth__circle:nth-child(4)',
            0.2,
            {
                clipPath: 'circle(0%)',
            },
            {
                clipPath: 'circle(37.5%)',
                ease: 'none'
            },
            0.375,
        );

        earth.fromTo(
            '.earth__wrap-gray .earth__circle:nth-child(5)',
            0.2,
            {
                clipPath: 'circle(0%)',
            },
            {
                clipPath: 'circle(43.5%)',
                ease: 'none'
            },
            0.375,
        );

        earth.fromTo(
            '.earth__wrap-gray .earth__circle:nth-child(9)',
            0.2,
            {
                clipPath: 'circle(0%)',
            },
            {
                clipPath: 'circle(50%)',
                ease: 'none'
            },
            0.375,
        );

        earth.fromTo(
            '.earth__wrap-gray .earth__circle:nth-child(6)',
            0.2,
            {
                clipPath: 'circle(0%)',
            },
            {
                clipPath: 'circle(50%',
                ease: 'none'
            },
            0.38,
        );

        earth.fromTo(
            '.earth__wrap-gray .earth__circle:nth-child(1)',
            0.2,
            {
                clipPath: 'circle(0%)',
            },
            {
                clipPath: 'circle(50%)',
                ease: 'none'
            },
            0.38,
        );

        earth.fromTo(
            '.earth__wrap-gray .earth__circle:nth-child(7)',
            0.2,
            {
                clipPath: 'circle(0%)',
            },
            {
                clipPath: 'circle(50%)',
                ease: 'none'
            },
            0.385,
        );

        earth.fromTo(
            '.earth__wrap-gray .earth__circle:nth-child(8)',
            0.2,
            {
                clipPath: 'circle(0%)',
            },
            {
                clipPath: 'circle(50%)',
                ease: 'none'
            },
            0.39,
        );

        // Appearance of orange sections
        earth.fromTo(
            '.earth__wrap-circle .earth__circle:nth-child(2)',
            0.2,
            {
                clipPath: 'circle(0%)',
            },
            {
                clipPath: 'circle(50%)',
                ease: 'none'
            },
            0.515,
        );

        earth.fromTo(
            '.earth__wrap-circle .earth__circle:nth-child(9)',
            0.2,
            {
                clipPath: 'circle(0%)',
            },
            {
                clipPath: 'circle(50%)',
                ease: 'none'
            },
            0.515,
        );

        earth.fromTo(
            '.earth__wrap-circle .earth__circle:nth-child(6)',
            0.2,
            {
                clipPath: 'circle(0%)',
            },
            {
                clipPath: 'circle(50%)',
                ease: 'none'
            },
            0.52,
        );

        earth.fromTo(
            '.earth__wrap-circle .earth__circle:nth-child(1)',
            0.2,
            {
                clipPath: 'circle(0%)',
            },
            {
                clipPath: 'circle(37.5%)',
                ease: 'none'
            },
            0.50,
        );

        earth.fromTo(
            '.earth__wrap-circle .earth__circle:nth-child(7)',
            0.2,
            {
                clipPath: 'circle(0%)',
            },
            {
                clipPath: 'circle(22.5%)',
            },
            0.50,
        );

        earth.fromTo(
            '.earth__wrap-circle .earth__circle:nth-child(8)',
            0.2,
            {
                clipPath: 'circle(0%)',
            },
            {
                clipPath: 'circle(20%)',
                ease: 'none'
            },
            0.440,
        );

        // Disappearance of orange sections
        earth.to(
            '.earth__wrap-circle .earth__circle:nth-child(2)',
            0.1425,
            {
                clipPath: 'circle(0%)',
                ease: 'none'
            },
            0.805,
        );

        earth.to(
            '.earth__wrap-circle .earth__circle:nth-child(9)',
            0.1425,
            {
                clipPath: 'circle(0%)',
                ease: 'none'
            },
            0.805,
        );

        earth.to(
            '.earth__wrap-circle .earth__circle:nth-child(6)',
            0.1425,
            {
                clipPath: 'circle(0%)',
                ease: 'none'
            },
            0.8,
        );

        earth.to(
            '.earth__wrap-circle .earth__circle:nth-child(1)',
            0.20,
            {
                clipPath: 'circle(0%)',
                ease: 'none'
            },
            0.78,
        );

        earth.to(
            '.earth__wrap-circle .earth__circle:nth-child(7)',
            0.3,
            {
                clipPath: 'circle(0%)',
                ease: 'none'
            },
            0.795,
        );

        earth.to(
            '.earth__wrap-circle .earth__circle:nth-child(8)',
            0.4025,
            {
                clipPath: 'circle(0%)',
                ease: 'none'
            },
            0.79,
        );

        // Missing black and white sections
        earth.to(
            '.earth__wrap-gray .earth__circle:nth-child(2)',
            0.1,
            {
                clipPath: 'circle(0%)',
                ease: 'none'
            },
            0.905,
        );

        earth.to(
            '.earth__wrap-gray .earth__circle:nth-child(3)',
            0.1,
            {
                clipPath: 'circle(0%)',
                ease: 'none'
            },
            0.905,
        );

        earth.to(
            '.earth__wrap-gray .earth__circle:nth-child(4)',
            0.1,
            {
                clipPath: 'circle(0%)',
            },
            0.905,
        );

        earth.to(
            '.earth__wrap-gray .earth__circle:nth-child(5)',
            0.1,
            {
                clipPath: 'circle(0%)',
                ease: 'none'
            },
            0.905,
        );

        earth.to(
            '.earth__wrap-gray .earth__circle:nth-child(9)',
            0.1,
            {
                clipPath: 'circle(0%)',
                ease: 'none'
            },
            0.905,
        );

        earth.to(
            '.earth__wrap-gray .earth__circle:nth-child(6)',
            0.1,
            {
                clipPath: 'circle(0%)',
                ease: 'none'
            },
            0.9,
        );

        earth.to(
            '.earth__wrap-gray .earth__circle:nth-child(1)',
            0.1,
            {
                clipPath: 'circle(0%)',
                ease: 'none'
            },
            0.9,
        );

        earth.to(
            '.earth__wrap-gray .earth__circle:nth-child(7)',
            0.1,
            {
                clipPath: 'circle(0%)',
                ease: 'none'
            },
            0.895,
        );

        earth.to(
            '.earth__wrap-gray .earth__circle:nth-child(8)',
            0.1,
            {
                clipPath: 'circle(0%)',
                ease: 'none'
            },
            0.89,
        );

        // Disappearance of the rest in the ground animation
        earth.to(
            '.earth__wrap-lines',
            0.1,
            {
                opacity: 0,
            },
            0.89,
        );

        earth.to(
            '.earth__wrap-names',
            0.1,
            {
                opacity: 0,
            },
            0.89,
        );

        earth.to(
            '._st-five',
            0.2,
            {
                opacity: 1,
            },
            1,
        );

        // Text animation
        gsap.fromTo(
            '._st-one .h1',
            0.6,
            {
                yPercent: 20,
                opacity: 0,
            },
            {
                yPercent: 0,
                opacity: 1,
            },
            0,
        );

        gsap.fromTo(
            '._st-one .p1',
            0.6,
            {
                yPercent: 100,
                opacity: 0,
            },
            {
                yPercent: 0,
                opacity: 1,
            },
            0,
        );

        let texts = gsap.timeline({
            scrollTrigger: {
                trigger: this.containerContent,
                start: 'top top',
                end: 'bottom bottom',
                scrub: this.scrubSpeed,
            },
        });

        texts.fromTo(
            '._st-one .h1',
            0.25,
            {
                yPercent: 0,
                opacity: 1,
            },
            {
                yPercent: -20,
                opacity: 0,
            },
            0,
        );

        texts.fromTo(
            '._st-one .p1',
            0.25,
            {
                yPercent: 0,
                opacity: 1,
            },
            {
                yPercent: -100,
                opacity: 0,
            },
            0,
        );

        texts.fromTo(
            '._st-two .h2',
            0.25,
            {
                yPercent: 20,
                opacity: 0,
            },
            {
                yPercent: 0,
                opacity: 1,
            },
            0.25,
        );

        texts.to(
            '._st-five',
            0.1,
            {
                opacity: 1,
            },
            0.5,
        );

        texts.to(
            '._st-two .h2',
            0.25,
            {
                yPercent: -20,
                opacity: 0,
            },
            0.6,
        );

        texts.fromTo(
            '._st-three .h3',
            0.25,
            {
                yPercent: 100,
                opacity: 0,
            },
            {
                yPercent: 0,
                opacity: 1,
            },
            0.85,
        );

        texts.fromTo(
            '._st-three .h2',
            0.25,
            {
                yPercent: 20,
                opacity: 0,
            },
            {
                yPercent: 0,
                opacity: 1,
            },
            0.9,
        );

        texts.fromTo(
            '._st-three .p2',
            0.25,
            {
                yPercent: 100,
                opacity: 0,
            },
            {
                yPercent: 0,
                opacity: 1,
            },
            0.95,
        );

        texts.to(
            '._st-five',
            0.1,
            {
                opacity: 1,
            },
            1.2,
        );

        texts.to(
            '._st-three .h3',
            0.1,
            {
                yPercent: -100,
                opacity: 0,
            },
            1.3,
        );

        texts.to(
            '._st-three .h2',
            0.25,
            {
                yPercent: -20,
                opacity: 0,
            },
            1.35,
        );

        texts.to(
            '._st-three .p2',
            0.25,
            {
                yPercent: -100,
                opacity: 0,
            },
            1.4,
        );

        texts.fromTo(
            '._st-four .h1',
            0.25,
            {
                yPercent: 20,
                opacity: 0,
            },
            {
                yPercent: 0,
                opacity: 1,
            },
            1.65,
        );

        texts.fromTo(
            '._st-four .p2',
            0.25,
            {
                yPercent: 20,
                opacity: 0,
            },
            {
                yPercent: 0,
                opacity: 1,
            },
            1.7,
        );

        texts.to(
            '._st-five',
            0.1,
            {
                opacity: 1,
            },
            1.95,
        );

        texts.to(
            '._st-four .h1',
            0.25,
            {
                yPercent: -20,
                opacity: 0,
            },
            2.05,
        );

        texts.to(
            '._st-four .p2',
            0.25,
            {
                yPercent: -100,
                opacity: 0,
            },
            2.1,
        );

        texts.fromTo(
            '._st-five .h1',
            0.25,
            {
                yPercent: 20,
                opacity: 0,
            },
            {
                yPercent: -5,
                opacity: 1,
            },
            2.35,
        );

        texts.to(
            '._st-five',
            0.25,
            {
                opacity: 1,
            },
            2.6,
        );

        texts.to(
            '._st-five .h1',
            0.25,
            {
                yPercent: -20,
                opacity: 0,
            },
            4.2,
        );

        texts.to(
            '._st-five',
            1,
            {
                opacity: 1,
            },
            4.85,
        );

        texts.fromTo(
            '.bottom-btn',
            0.25,
            {
                pointerEvents: 'none',
            },
            {
                pointerEvents: 'all',
            },
            4.85,
        );

        texts.fromTo(
            '._st-six .h3',
            0.25,
            {
                yPercent: 20,
                opacity: 0,
            },
            {
                yPercent: 0,
                opacity: 1,
            },
            4.55,
        );

        // Animating the text in via reveal, this is only needed if fading the content in.
        // texts.fromTo(
        //     '._st-six .h2-v2',
        //     0.25,
        //     {
        //         opacity: 0,
        //     },
        //     {
        //         opacity: 1,
        //     },
        //     4.55,
        // );

        texts.fromTo(
            '.bottom-btn',
            0.25,
            {
                opacity: 0,
            },
            {
                opacity: 1,
            },
            4.85,
        );

        texts.to(
            '._st-five',
            1.5,
            {
                opacity: 1,
            },
            6.1,
        );


        // Text reveal
        const attr = 'data-earth-reveal-text';
        const splitTypes = document.querySelectorAll(`[${attr}]`);

        splitTypes.forEach((item, i) => {
            const type = item.getAttribute(attr);
            const text = new SplitType(item, { types: ['chars', 'words'] });

            let texts = gsap.timeline({
                scrollTrigger: {
                    trigger: this.containerContent,
                    start: 'top top',
                    end: 'bottom bottom',
                    scrub: this.scrubSpeed,
                },
            });

            if (item == document.querySelector('.title-reveal')) {
                texts.to(
                    '._st-five',
                    3.85,
                    {
                        opacity: 1,
                    },
                    0,
                );
                texts.to(
                    item,
                    0,
                    {
                        opacity: 1,
                    },
                    0,
                );
                texts.from(
                    text[type],
                    0.25,
                    {
                        opacity: 0,
                        color: 'transparent',
                        stagger: 0.01,
                    },
                    3.85,
                );
                texts.to(
                    '._st-five',
                    1.5,
                    {
                        opacity: 1,
                    },
                    4.6,
                );
            }
        });
    }
}

export default new Earth();
