// Imports
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

/**
 * Pinned Background Module
 *
 * Example Markup:
 *
 *
 */
class PinnedBg {
    constructor() {
        this.attr = 'data-pinned-bg';
    }

    init() {
        this.components = document.querySelectorAll(`[${this.attr}]`);

        this.components.forEach((component, i) => {
            const content = component.querySelector('.pinned-bg-content');
            const image = component.querySelector('.pinned-bg-image');

            gsap.timeline({
                scrollTrigger: {
                    trigger: content,
                    pin: image,
                    pinSpacing: true,
                    start: 'top top',
                    end: 'bottom bottom',
                    markers: false,
                }
            });
        });
    }
}

export default new PinnedBg();
