import DelayedCall from '@js/helpers/DelayedCall';
import PageScrollingManager from '@js/modules/PageScrollingManager';

class AppearParent {
    constructor() {
        this.appearTargets = [];
    }
    init() {
        this._setup();
    }
    clear() {
        this.appearTargets.forEach((target) => {
			PageScrollingManager.unwatch(target);
		});
    }
    _setup() {
        const attrName = 'data-appear-parent';

        // Appear elements
		const appearElements = document.querySelectorAll('[' + attrName + ']');

        // Callback for IntersectionObserver
        const appearCallback = function(entries) {
            entries.forEach(entry => {
                const attributeValues = entry.target
    				.getAttribute(attrName)
    				.split(',');

                // Get the default delay duration
                const delay = Number.parseFloat(attributeValues[0] || 0);
                // Get the delay inbetween elements
                const delayBetween = Number.parseFloat(attributeValues[1] || 70);
                // Get the child elements
                const childEls = attributeValues[2] || '.appear-item';
                // Get the class to add to the element
    			const addClass = attributeValues[3] || '_visible';

                const getChildEls = entry.target.querySelectorAll(childEls);

                // Is the element in the viewport?
                if (entry.isIntersecting) {
                    entry.target.querySelectorAll(childEls).forEach((e, i) => {
                        let delayedCallDelay = delay + (delayBetween * i);

                        DelayedCall.create(delayedCallDelay, () => {
    						e.classList.add(addClass);
    					});
                    });
                    appearObserver.unobserve(entry.target);
                }
            });
        };

        const appearObserver = new IntersectionObserver(appearCallback);
		appearElements.forEach((target) => {
            // Add the element to the watcher
            appearObserver.observe(target);
		});
    }
}

export default new AppearParent();
