import env from '@js/utils/env';
import DelayedCall from '@js/helpers/DelayedCall';

class Appear {
    constructor() {
        this.attrName = 'data-appear';
        this.appearObserver = null;
        this.appearTargets = [];
        this.currentViewportWidth = window.innerWidth;
        this._handleResize = this._handleResize.bind(this);
    }

    // Initialize the appear functionality
    init() {
        this._setup();
        window.addEventListener('resize', this._handleResize);
    }

    // Clear the appear functionality
    clear() {
        if (this.appearObserver) {
            this.appearTargets.forEach(target => {
                this.appearObserver.unobserve(target);
            });
            this.appearObserver.disconnect();
        }
        this.appearTargets = [];
        window.removeEventListener('resize', this._handleResize);
    }

    _setup() {
        // Appear elements
        const appearElements = document.querySelectorAll(`[${this.attrName}]`);

        // Callback for IntersectionObserver
        const appearCallback = (entries) => {
            entries.forEach(entry => {
                const attributeValues = entry.target.getAttribute(this.attrName).split(',');

                // Get the default delay duration
                const delay = Number.parseFloat(attributeValues[0] || 0);
                // Get the mobile delay duration
                const delayMobile = Number.parseFloat(attributeValues[1] || 0);
                // Get the class to add to the element
                const addClass = attributeValues[2] || '_visible';

                // Is the element in the viewport?
                if (entry.isIntersecting) {
                    let delayedCallDelay = this.currentViewportWidth < 768 ? delayMobile : delay;

                    DelayedCall.create(delayedCallDelay, () => {
                        entry.target.classList.add(addClass);
                        this.appearObserver.unobserve(entry.target);
                    });
                }
            });
        };

        // Initialize the IntersectionObserver
        this.appearObserver = new IntersectionObserver(appearCallback, {
            threshold: 0.17 // Adjust threshold as needed
        });

        // Observe each appear element and store them
        appearElements.forEach((target) => {
            this.appearObserver.observe(target);
            this.appearTargets.push(target);
        });
    }

    _handleResize() {
        const newViewportWidth = window.innerWidth;

        if (newViewportWidth !== this.currentViewportWidth) {
            this.currentViewportWidth = newViewportWidth;

            // Update delays based on the new viewport width
            this.appearTargets.forEach(target => {
                const attributeValues = target.getAttribute(this.attrName).split(',');

                // Get the default delay duration
                const delay = Number.parseFloat(attributeValues[0] || 0);
                // Get the mobile delay duration
                const delayMobile = Number.parseFloat(attributeValues[1] || 0);

                // Determine which delay to use based on viewport width
                const delayedCallDelay = this.currentViewportWidth < 768 ? delayMobile : delay;

                target.style.transitionDelay = `${delayedCallDelay}s`;
            });
        }
    }
}

export default new Appear();
