class ElementsHeightCalc {
	constructor() {
        this.calulate();

        document.addEventListener('DOMContentLoaded', e => {
            this.calulate();
        });

        // We listen to the resize event
        window.addEventListener('load', () => {
            this.calulate();
        });

        // We listen to the resize event
        window.addEventListener('resize', () => {
            this.calulate();
        });
	}
    calulate() {
        const header = document.querySelector('.mn-hdr');
        const footer = document.querySelector('.mn-ftr');

        this.setVar(header, 'hh');
        this.setVar(footer, 'fh');
    }

    setVar(element, varName) {
        if (element) {
            // Get the element height
            let height = element.offsetHeight;

            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty(`--${varName}`, `${height}px`);
        }
        else {
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty(`--${varName}`, '0px');
        }
    }
}

export default new ElementsHeightCalc();
