// Imports
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

/**
 * This script enables smooth scrolling to specific sections on the webpage when elements with the
 * 'data-scroll-to' attribute are clicked. The scrolling behavior is animated using the GSAP library.
 * The 'data-scroll-to' attribute can take up to three comma-separated values:
 *
 * 1. The target element to scroll to, this can be an element of a value (required).
 * 2. The duration of the scrolling animation (optional, defaults to 0.5 seconds).
 * 3. An offset value to adjust the final scroll position (optional, defaults to 0).
 */
class ScrollTo {
    constructor() {
        this.attrName = 'data-scroll-to';

        document.querySelectorAll(`[${this.attrName}]`).forEach(element => {
            element.addEventListener('click', (e) => {
                e.preventDefault();
                this.dataAction(element.getAttribute(this.attrName));
            });
        });
    }

    dataAction(attr) {
        const [to, duration = 0.5, offset = 0] = attr.split(',').map(item => item.trim());

        this.scrollAction(to, duration, offset);
    }

    scrollAction(to, duration, offset) {
        // Convert duration and offset to numbers
        const scrollDuration = parseFloat(duration);
        const scrollOffset = parseInt(offset, 10);

        gsap.to(window, {
            duration: window.REGENERATION.env.reducedMotion ? 0 : scrollDuration,
            scrollTo: {
                y: to,
                offsetY: scrollOffset
            }
        });
    }
}

export default new ScrollTo();
