// Imports
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

class Strategy {
    constructor() {
        this.containerContent = document.querySelector('.strategy-cards');
    }

    init() {
        // If no container content, don't do anything
        if (!this.containerContent) {
            return;
        }

        let mm = gsap.matchMedia();

        let cards = this.containerContent.querySelectorAll('.strategy-card');
        let cardsCount = 0;
        cards.forEach((card) => {
            cardsCount++;
        });
        let cardsHeight = this.containerContent.clientHeight * cardsCount;

        mm.add('(min-width: 769px)', () => {
            let cards = gsap.timeline({
                scrollTrigger: {
                    trigger: this.containerContent,
                    start: 'top bottom',
                    end: 'top top-=' + (cardsHeight/2),
                    scrub: 2,
                },
            });

            cards.fromTo(
                '.strategy-card:nth-child(3n-2)',
                {
                    yPercent:  150,
                },
                {
                    yPercent: 0,
                },
                0,
            );

            cards.fromTo(
                '.strategy-card:nth-child(3n-1)',
                {
                    yPercent:  150,
                },
                {
                    yPercent: 0,
                },
                0.1,
            );

            cards.fromTo(
                '.strategy-card:nth-child(3n)',
                {
                    yPercent:  150,
                },
                {
                    yPercent: 0,
                },
                0.2,
            );
        });

        mm.add('(max-width: 768px)', () => {

            let cardsAnim = gsap.timeline({
                scrollTrigger: {
                    trigger: '.strategy',
                    start: 'top top',
                    end: 'top top-=' + (cardsHeight*2),
                    scrub: 1,
                    invalidateOnRefresh: true,
                },
            });
            let n = 0;
            cards.forEach((card) => {
                n++;
                if (n != 1) {
                    cardsAnim.fromTo(
                        card,
                        {
                            yPercent: 100,
                        },
                        {
                            yPercent: 0,
                        },
                    );
                    cardsAnim.fromTo(card, 0.1, {
                        opacity:1,
                    }, {
                        opacity:1,
                    })
                }
            });
        });


        let blockHeight = document.querySelector('.strategy-cards').clientHeight;
        function resizeBlock(on, x, x2) {
            if (on == true) {
                let cards = document.querySelectorAll('.strategy-card');
                let cardsCount = 0;
                cards.forEach((card) => {
                    cardsCount++;
                });
                let cardsHeight = document.querySelector('.strategy-cards').clientHeight * cardsCount;
                document.querySelector('.strategy').style.height = (blockHeight * x2) + (cardsHeight * x) + 'px';
            } else {
                document.querySelector('.strategy').style.height = 'auto';
            }
        }

        if (innerWidth > 768) {
            resizeBlock(true, 1, 2)
        } else {
            resizeBlock(true, 2, 1)
        }

        window.addEventListener('resize', ()=> {
            if (innerWidth > 768) {
                resizeBlock(true, 1, 2)
            } else {
                resizeBlock(true, 2, 1)
            }
        })
    }
}

export default new Strategy();
