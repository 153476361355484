import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from 'lenis';

class SmoothScrolling {
    constructor() {
        // Lenis
        this.lenis = new Lenis();

        // lenis.on('scroll', (e) => {
        //     console.log(e);
        // });

        this.lenis.on('scroll', ScrollTrigger.update);

        gsap.ticker.add((time)=>{
            this.lenis.raf(time * 1000);
        });

        gsap.ticker.lagSmoothing(0);
    }

    stop() {
        this.lenis.stop();
    }

    start() {
        this.lenis.start();
    }
}

export default new SmoothScrolling();
