/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR');
    });
}

// JS imports
import 'lazysizes';
import Headroom from "headroom.js";
import htmx from 'htmx.org';
import 'sharer.js';

// Alpine
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';

// Project Imports
import env from '@js/utils/env';
import utils from '@js/utils/utils';
import Signal from '@js/classes/Signal';
import DelayedCall from '@js/helpers/DelayedCall';
import ElementsHeightCalc from '@js/helpers/ElementsHeightCalc';

import CookieMessage from '@js/modules/CookieMessage';
import MobileFilters from '@js/modules/MobileFilters';
// import HeaderScroll from '@js/modules/HeaderScroll';
import Navigation from '@js/modules/Navigation';
import Page from '@js/modules/Page';
import PageScrollingManager from '@js/modules/PageScrollingManager';
import PageScrolling from '@js/modules/PageScrolling';
import ScrollTo from '@js/modules/ScrollTo';
import SmoothScrolling from '@js/modules/SmoothScrolling';
import Team from '@js/modules/Team';

// Alpine setup
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.start();
window.Alpine = Alpine;

// HTMX
// Overrides the request class (default is `htmx-request`)
htmx.config.requestClass = 'loading';

// Headroom
const headerEl = document.querySelector('.mn-hdr');
let headroom  = new Headroom(headerEl, {
    offset: {
        down: 100,
        up: 50,
    },
    tolerance : {
        down: 5,
        up: 10,
    },
});

window.REGENERATION =
    window.REGENERATION ||
    new (function REGENERATION() {
        this.env = env;

        this.utils = utils;

        this.classes = {
            Signal: Signal,
        };

        this.helpers = {
            DelayedCall: DelayedCall,
            ElementsHeightCalc: ElementsHeightCalc,
            headroom: headroom,
        };

        this.modules = {
            PageScrollingManager: PageScrollingManager,
            PageScrolling: PageScrolling,
            SmoothScrolling: SmoothScrolling,
        };

        PageScrolling.start();

        // Window Load
        window.addEventListener('load', e => {
            PageScrollingManager.update(true);
        });

        // DOM load
        document.addEventListener('DOMContentLoaded', e => {
            Page.init();

            // Init headroom
            headroom.init();

            // Reinit search after swap
            htmx.on('htmx:afterSettle', (event) => {
                Page.reinit();

                console.log(Alpine.store('filters').currentFilter);

                // Scroll to top
                let header = document.querySelector('.mn-hdr');
                let scrollOffset = 20;
                if (header) {
                    scrollOffset += header.offsetHeight;
                }
                ScrollTo.scrollAction(document.getElementById('scroll-after-settle'), 0.3, scrollOffset);
            });
        });

        // Escape key press
        document.onkeydown = (e) => {
            // Escape key maps to keycode `27`
            if (e.key === 'Escape') {
                // Mobile nav
                if (Alpine.store('nav').isOpen == true) {
                    Alpine.store('nav').close();
                }

                // Filters
                if (Alpine.store('filters').currentFilter !== null) {
                    Alpine.store('filters').closeFilters();
                }

                // Team
                if (Alpine.store('team').isOpen !== null) {
                    Alpine.store('team').close();
                }
            }
        };

        /* Window resize */
        window.onresize = function(event) {
            // Equal or greater than 1024px
            if (window.innerWidth >= 1024) {
                // Mobile nav
                if (Alpine.store('nav').isOpen == true) {
                    Alpine.store('nav').close();
                }

                // Filters
                if (Alpine.store('filters').currentFilter !== null) {
                    Alpine.store('filters').closeFilters();
                }
            }

            // Less than 1024px
            // if (window.innerWidth < 1024) {
            //     // Team
            //     if (Alpine.store('team').isOpen !== null) {
            //         Alpine.store('team').close();
            //     }
            // }
        };
    })();
