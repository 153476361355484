// Imports
import Alpine from 'alpinejs';

class Team {
    constructor() {
        // Set up Alpine store to control the team section
        Alpine.store('team', {
            isOpen: null,
            open(entryId, url) {
                // Open nav
                this.isOpen = entryId;
                console.log('Team open: ', this.isOpen);

                // Screen is large enough to show the content on page
                // if (window.innerWidth > 1024) {
                //     // Perform another action
                //     console.log('Window is greater than 1024px');
                // }
                // else {
                //     // Open a new page
                //     window.location.href = url;
                // }
            },
            close() {
                // Close nav
                this.isOpen = null;
            }
        });
    }
}

export default new Team();
