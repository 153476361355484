// Imports
import Alpine from 'alpinejs';

// Project imports
import PageScrolling from '@js/modules/PageScrolling';

class MobileFilters {
    constructor() {
        // Set up Alpine store to control filters
        Alpine.store('filters', {
            currentFilter: null,

            openFilters(filterId) {
                console.log('Open filters: ', filterId);
                this.currentFilter = filterId;

                // Get filters element
                const filterEl = document.getElementById(this.currentFilter);

                // Lock page scroll
                PageScrolling.lock(filterEl);
            },

            closeFilters() {
                // Get filters element
                const filterEl = document.getElementById(this.currentFilter);

                console.log('Close filters: ', this.currentFilter);

                // Unlock page scroll
                PageScrolling.unlock(filterEl);

                // Close filters
                this.currentFilter = null;
            }
        });
    }
}

export default new MobileFilters();
