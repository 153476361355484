// Imports
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

class Parallax {
    constructor() {
        this.attr = 'data-bg-parallax';
    }

    // Initialize the appear functionality
    init() {
        this._setup();
    }

    _setup() {
        const parallaxEls = document.querySelectorAll(`[${this.attr}]`);

        parallaxEls.forEach((el) => {
            let parallax = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: 2,
                },
            });

            parallax.fromTo(el.querySelector('.parallax-img'), {
                yPercent: -20,
                scale: 1.2,
            }, {
                yPercent: 0,
                scale: 1.2,
            });
        });
    }
}

export default new Parallax();
