// Imports
import PageScrolling from '@js/modules/PageScrolling';

class ImageZoom {
    constructor() {
        this.attr = 'data-zoomable';
    }

    init() {
        this.zoomableElements = document.querySelectorAll(`[${this.attr}]`);

        this.zoomableElements.forEach((element) => {
            const targetId = element.getAttribute(this.attr);
            const targetElement = document.getElementById(targetId);

            if (!targetElement) {
                console.warn(`No element found with ID: ${targetId}`);
                return;
            }

            // Add event listener for zoom effect on trigger click
            element.addEventListener('click', () => this.toggleZoom(targetElement, element));

            // Add event listener to close the zoomed target when it's clicked
            targetElement.addEventListener('click', () => this.toggleZoom(targetElement, element));

            // Add key listener for accessibility
            element.addEventListener('keydown', (e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    this.toggleZoom(targetElement, element);
                }
            });

            // Set up accessible attributes
            element.setAttribute('aria-controls', targetId);
            element.setAttribute('aria-expanded', 'false');
        });

        // Add keydown listener on document for Esc key to close any open target
        document.addEventListener('keydown', (e) => this.handleEscKey(e));
    }

    toggleZoom(targetElement, triggerElement) {
        const isZoomed = targetElement.classList.toggle('zoomed');

        // Update accessible attributes
        triggerElement.setAttribute('aria-expanded', isZoomed);

        // Scroll control
        if (isZoomed) {
            PageScrolling.lock(targetElement);
            window.REGENERATION.helpers.headroom.unpin();
        }
        else {
            PageScrolling.unlock(targetElement);
        }

        // Track currently zoomed element for Esc key closure
        this.currentZoomedElement = isZoomed ? targetElement : null;
        this.currentTriggerElement = isZoomed ? triggerElement : null;
    }

    handleEscKey(e) {
        // Check if the Esc key was pressed and a target is currently zoomed
        if (e.key === 'Escape' && this.currentZoomedElement) {
            this.toggleZoom(this.currentZoomedElement, this.currentTriggerElement);
        }
    }
}

// Export factory function
export default new ImageZoom();
