// Imports
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

/**
 * Marquee Module
 *
 * This module allows you to create a marquee effect on your website using GSAP and ScrollTrigger.
 *
 * How to Use:
 * 1. Add the `data-marquee` attribute to any element you want to turn into a marquee.
 * 2. The `data-marquee` attribute should contain a JSON string with the options you want to customize.
 * 3. Include the necessary markup structure inside the marquee element.
 *
 * Example Markup:
 *
 * <div class="marquee" data-marquee='{
 *     "speed": 100,
 *     "speedMobile": 70,
 *     "vertical": false,
 *     "reverse": false,
 *     "scrollDirection": true,
 *     "scrollOnly": false,
 *     "scrollScrub": false,
 *     "pauseHover": false,
 *     "pauseClick": false,
 *     "panelElement": '.marquee-panel',
 * }'>
 *     <div class="marquee-wrapper">
 *         <div class="marquee-panel">
 *             Content to scroll
 *         </div>
 *         <div class="marquee-panel">
 *             Content to scroll
 *         </div>
 *     </div>
 * </div>
 *
 * Options Description:
 * - speed: Number (default: 100)
 *   - The speed of the marquee movement. Higher values mean faster movement.
 *
 *   - speedMobile: Number (default: 50)
 *   - The speed of the marquee movement for smaller screens. Higher values mean faster movement.
 *
 * - vertical: Boolean (default: false)
 *   - If true, the marquee will scroll vertically. Otherwise, it will scroll horizontally.
 *
 * - reverse: Boolean (default: false)
 *   - If true, the marquee will scroll in the opposite direction.
 *
 * - scrollDirection: Boolean (default: true)
 *   - If true, the marquee will change direction based on the scroll direction of the page.
 *
 * - scrollOnly: Boolean (default: false)
 *   - If true, the marquee will only scroll when the page is scrolled. Otherwise, it scrolls by default.
 *
 * - scrollScrub: Boolean (default: false)
 *   - If true, the speed of the marquee will adjust based on the scroll speed of the page.
 *
 * - pauseHover: Boolean (default: false)
 *   - If true, the marquee will pause when the user hovers over it.
 *
 * - pauseClick: Boolean (default: false)
 *   - If true, the marquee will pause when clicked, and resume when clicked again.
 *
 * - panelElement: String (default: '.marquee-panel')
 *   - The marquee panel selector.
 */
 class Marquee {
    constructor() {
        this.attr = 'data-marquee';
    }

    init() {
        // Marquee component
        document.querySelectorAll(`[${this.attr}]`).forEach(component => {
            this._setup(component);
        });
    }

    _setup(component) {
        // Default settings
        let defaultOptions = {
            speed: 100,
            speedMobile: 50,
            vertical: false,
            reverse: false,
            scrollDirection: true,
            scrollScrub: false,
            scrollOnly: false,
            pauseHover: false,
            pauseClick: false,
            panelElement: '.marquee-panel',
        };

        // Parse data-marquee attribute and merge with default options
        let dataOptions = this._parseOptions(component.getAttribute(this.attr));
        let options = { ...defaultOptions, ...dataOptions };

        // Check if the screen size is smaller than 768px
        const isMobile = window.matchMedia('(max-width: 768px)').matches;
        if (isMobile) {
            options.speed = options.speedMobile; // Use mobile speed if screen size is smaller than 768px
        }

        let panelEls = component.querySelectorAll(options.panelElement);
        let moveDistanceSetting = options.reverse ? 100 : -100;
        let timeScaleSetting = 1;
        let pausedStateSetting = false;

        if (options.reverse) {
            component.classList.add('marquee-reverse');
        }

        // Create marquee timeline
        let marqueeTimeline = gsap.timeline({
            repeat: -1,
            paused: options.scrollOnly, // Start paused for scrollOnly mode
            onReverseComplete: () => marqueeTimeline.progress(1)
        });

        // Calculate speed and set animation direction
        if (options.vertical) {
            options.speed = panelEls[0].offsetHeight / options.speed;
            marqueeTimeline.fromTo(panelEls, { yPercent: 0 }, {
                yPercent: moveDistanceSetting,
                ease: 'none',
                duration: options.speed
            });
        } else {
            options.speed = panelEls[0].offsetWidth / options.speed;
            marqueeTimeline.fromTo(panelEls, { xPercent: 0 }, {
                xPercent: moveDistanceSetting,
                ease: 'none',
                duration: options.speed
            });
        }

        // ScrollTrigger to control animation direction and speed
        let scrubObject = { value: 1 };
        let lastScrollTop = 0;
        let isScrolling = false;
        let scrollTimeout;

        ScrollTrigger.create({
            trigger: 'body',
            start: 'top top',
            end: 'bottom bottom',
            onUpdate: (self) => {
                if (!pausedStateSetting) {
                    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                    let scrollDirection = scrollTop > lastScrollTop ? 1 : -1;
                    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling

                    clearTimeout(scrollTimeout);
                    isScrolling = true;

                    if (options.scrollOnly) {
                        // Handle the scrollDirection and scrollScrub options as before
                        if (options.scrollDirection && self.direction !== marqueeTimeline.timeScale()) {
                            marqueeTimeline.timeScale(self.direction);
                        }

                        // Calculate the velocity-based scrolling effect
                        let v = self.getVelocity() * 0.006;
                        v = gsap.utils.clamp(-60, 60, v);

                        // Set the direction based on scroll direction
                        let targetValue = v * scrollDirection;

                        gsap.to(scrubObject, {
                            value: targetValue,
                            duration: 0.5,
                            onUpdate: () => {
                                if (isScrolling) {
                                    marqueeTimeline.timeScale(scrubObject.value);
                                    marqueeTimeline.play();
                                }
                            }
                        });

                        // Set a timeout to pause the marquee after scrolling stops
                        scrollTimeout = setTimeout(() => {
                            isScrolling = false;
                            marqueeTimeline.pause();
                        }, 100);
                    } else {
                        // Handle the scrollDirection and scrollScrub options as before
                        if (options.scrollDirection && self.direction !== marqueeTimeline.timeScale()) {
                            marqueeTimeline.timeScale(self.direction);
                        }
                        if (options.scrollScrub) {
                            let v = self.getVelocity() * 0.006;
                            v = gsap.utils.clamp(-60, 60, v);

                            gsap.to(scrubObject, {
                                value: v,
                                duration: 0.5,
                                onUpdate: () => marqueeTimeline.timeScale(scrubObject.value)
                            });
                        }
                    }
                }
            }
        });

        // Event listeners for pause on hover and click
        if (options.pauseHover && window.matchMedia('(pointer: fine)').matches) {
            component.addEventListener('mouseenter', () => this._pauseMarquee(true, marqueeTimeline, scrubObject, timeScaleSetting, component));
            component.addEventListener('mouseleave', () => this._pauseMarquee(false, marqueeTimeline, scrubObject, timeScaleSetting, component));
        }

        if (options.pauseClick) {
            component.addEventListener('click', () => {
                let isPaused = component.classList.contains('is-paused');
                this._pauseMarquee(!isPaused, marqueeTimeline, scrubObject, timeScaleSetting, component);
            });
        }
    }

    _parseOptions(data) {
        try {
            return data ? JSON.parse(data) : {};
        } catch (e) {
            console.error('Invalid JSON in data-marquee attribute:', e);
            return {};
        }
    }

    _pauseMarquee(isPausing, timeline, scrubObject, timeScale, component) {
        let pauseObject = { value: 1 };
        let pauseTimeline = gsap.timeline({
            onUpdate: () => timeline.timeScale(pauseObject.value)
        });

        if (isPausing) {
            pauseTimeline.fromTo(pauseObject, { value: timeScale }, { value: 0, duration: 0.5 });
            component.classList.add('is-paused');
        } else {
            pauseTimeline.fromTo(pauseObject, { value: 0 }, { value: timeScale, duration: 0.5 });
            component.classList.remove('is-paused');
        }
    }
}

export default new Marquee();
