class CookieMessage {
    constructor() {
        this.lsItemName = '_cookie-message';

        const lsCookieMessage = localStorage.getItem(this.lsItemName);

        if (!lsCookieMessage) {
            // Make an AJAX request to get data from the API
            fetch('/api/v1/cookie-message.json')
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    const { show, message } = data;

                    if (show === true || show === 1) {
                        this.addMessageToDOM(message);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }

    addMessageToDOM(message) {
        // Set local storage item
        localStorage.setItem(this.lsItemName, 'true');

        const gdprContainer = document.createElement('div');
        gdprContainer.className = 'fixed bottom-5 left-0 w-full z-50';
        gdprContainer.innerHTML = `
            <div class="container">
                <div class="bg-white flex flex-col gap-3 p-6 rounded-lg text-base shadow-lg w-full md:flex-row md:gap-5 md:items-center md:justify-between md:rounded-xl">
                    <div>
                        ${message}
                    </div>
                    <div>
                        <button id="cookie-message-close" class="btn btn-xs btn-border-black" type="button">Close</button>
                    </div>
                </div>
            </div>
        `;
        document.body.appendChild(gdprContainer);

        // Add event listener to close button
        document.getElementById('cookie-message-close').addEventListener('click', () => {
            gdprContainer.style.display = 'none';
        });
    }
}

export default new CookieMessage();
