// Imports
import SplitType from 'split-type';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

/**
 * RevealText Module
 *
 * This module animates text on a webpage as the user scrolls. It leverages the `gsap` library with the `ScrollTrigger` plugin and `SplitType`
 * to split text into individual words or characters and then applies a scrubbing animation effect based on the user's scroll position.
 *
 * Key Functionality:
 * - The module targets elements with the attribute `data-reveal-type`.
 * - Depending on the value of the `data-reveal-type` attribute (`words` or `chars`), it splits the text inside the targeted element into individual words or characters using `SplitType`.
 * - The `gsap` animation library is then used to create a scrolling effect where the text fades in from an opacity of 0.1 to full opacity as the user scrolls.
 * - The animation triggers when the element enters the viewport, starting when the top of the element reaches 50% of the viewport and ending when the bottom reaches 50%.
 *
 * Markup Requirements:
 * - Add the `data-reveal-text` attribute to any HTML element containing text you want to animate.
 * - Set the attribute value to either `words` (to animate whole words) or `chars` (to animate individual characters).
 *
 * Example Markup:
 *
 * <div data-reveal-text='{
 *     "type": "chars",
 *     "opacity": 0.1,
 *     "color": "#000",
 *     "stagger": 0.5,
 *     "start": "top 50%",
 *     "end": "bottom 50%",
 *     "markers": false,
 * }'>
 *     Lorem ipsum
 * </div>
 *
 * Options Description:
 * - type: String (default: 'chars') - ('chars'|'words')
 *   - The animation type. Characters or Words.
 *
 * - opacity: Int|Float (default: 0.1)
 *   - The opacity of the text before animation
 *
 * - color: String (default: '#000')
 *   - The color of the text before animation
 *
 * - stagger: Int|Float (default: 0.5)
 *   - The scrollTrigger stagger amount
 *
 * - start: String (default: 'top 50%')
 *   - Where the scroll animation should start.
 *
 * - end: String (default: 'bottom 50%')
 *   - Where the scroll animation should end
 *
 * - markers: Boolean (default: false)
 *   - If true, the GSAP markers will show for the scroll animation.
 */
class RevealText {
    constructor() {
        this.attr = 'data-reveal-text';
    }

    init() {
        this.components = document.querySelectorAll(`[${this.attr}]`);

        this.components.forEach((component, i) => {
            // Default settings
            let defaultOptions = {
                type: 'chars',
                opacity: 0.1,
                color: '#000',
                stagger: 0.5,
                start: 'top 50%',
                end: 'bottom 50%',
                markers: false,
            };

            // Parse data-marquee attribute and merge with default options
            let dataOptions = this._parseOptions(component.getAttribute(this.attr));
            let options = { ...defaultOptions, ...dataOptions };

            const text = new SplitType(component, { types: ['chars', 'words'] });

            gsap.from(text[options.type], {
                scrollTrigger: {
                    trigger: component,
                    start: options.start,
                    end: options.end,
                    scrub: true,
                    markers: options.markers,
                },
                opacity: options.opacity,
                color: options.color,
                stagger: options.stagger,
            });
        });
    }

    _parseOptions(data) {
        try {
            return data ? JSON.parse(data) : {};
        } catch (e) {
            console.error('Invalid JSON in data-reveal-text attribute:', e);
            return {};
        }
    }
}

export default new RevealText();
