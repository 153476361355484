class PageMessage {
    constructor() {
        // Configuration
        this.display_class = 'show';  // The class to add to display the element
        this.element_id = 'page-message';  // The ID of the element you want to show
        this.storage_key = `shown_${this.element_id}`;  // Key to track display status in sessionStorage
        this.close_btn_class = 'close-btn';  // The class of the close button within the element
    }

    // Initialize the appear functionality
    init() {
        const element = document.getElementById(this.element_id);

        // If the element doesn't exist, exit early
        if (!element) return;

        // Check if the element has been shown in the current session
        const hasBeenShown = sessionStorage.getItem(this.storage_key);

        // If it hasn't been shown, display it and mark it in sessionStorage
        if (!hasBeenShown) {
            element.classList.add(this.display_class);
        }

        // Attach close event handler to the close button
        const closeButton = element.querySelector(`.${this.close_btn_class}`);
        if (closeButton) {
            closeButton.addEventListener('click', () => this.hideElement(element));
        }
    }
    // Hide the element by removing the display class
    hideElement(element) {
        element.classList.remove(this.display_class);

        // Store session data if message is closed
        sessionStorage.setItem(this.storage_key, 'true');
    }
}

export default new PageMessage();
