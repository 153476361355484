// Imports
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

class Portfolio {
    constructor() {
        this.containerContent = document.querySelector('.portfolio-cards');
    }

    init() {
        // If no container content, don't do anything
        if (!this.containerContent) {
            return;
        }
        let mm = gsap.matchMedia();

        mm.add('(min-width: 769px)', () => {
            let cards = gsap.timeline({
                scrollTrigger: {
                    trigger: this.containerContent,
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: 2,
                },
            });

            cards.fromTo(
                '.portfolio-card:nth-child(1)',
                {
                    yPercent: 0,
                },
                {
                    yPercent: -35,
                    ease: 'out-in',
                },
                0,
            );

            cards.fromTo(
                '.portfolio-card:nth-child(2)',
                {
                    yPercent: 40,
                },
                {
                    yPercent: -10,
                    ease: 'in-out',
                },
                0,
            );

            cards.fromTo(
                '.portfolio-card:nth-child(3)',
                {
                    yPercent: 10,
                },
                {
                    yPercent: -85,
                    ease: 'none',
                },
                0,
            );
        });

        this.containerContent.querySelectorAll('.portfolio-card-inner').forEach(card => {
            let cardsParallax = gsap.timeline({
                scrollTrigger: {
                    trigger: card,
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: 2,
                },
            });

            cardsParallax.fromTo(card.querySelector('.portfolio-card-background img'), {
                yPercent: -12,
                scale: 1.2,
            }, {
                yPercent: 0,
                scale: 1.2,
            });
        });

    }
}

export default new Portfolio();
