// Imports
import SplitType from 'split-type';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

class SplitText {
    constructor() {
        this.attr = 'data-split-text';
        this.animations = new Map(); // Store animations by element
    }

    init() {
        const splitTextEls = document.querySelectorAll(`[${this.attr}]`);
        splitTextEls.forEach(textEl => this._animateText(textEl));
    }

    _animateText(textEl) {
        // Check if there's an existing animation for this element and reset it
        if (this.animations.has(textEl)) {
            const existingAnimation = this.animations.get(textEl);
            existingAnimation.progress(1).kill();  // Complete and kill the old animation
            existingAnimation.revert();  // Revert any applied changes
        }

        // Initialize SplitType on the text element
        const text = new SplitType(textEl, { types: 'lines, words, chars' });

        // Create the animation
        const splitTextAni = gsap.from(text?.words, {
            scrollTrigger: {
                trigger: textEl,
                once: true,
                start: 'top 80%',
            },
            duration: 0.5,
            ease: 'circ.out',
            y: '100%',
            stagger: 0.02,
        });

        // Store the animation instance
        this.animations.set(textEl, splitTextAni);

        // Reposition text after the container is resized (simplified version)
        // This example uses lodash#debounce to ensure the split method only
        // gets called once after the resize is complete.
        const resizeObserver = new ResizeObserver(
            window.REGENERATION.utils.debounce(([entry]) => {
                // Note: you should add additional logic so the `split` method is only
                // called when the **width** of the container element has changed.
                text.split();
                console.log(text);
            }, 100)
        )
        resizeObserver.observe(textEl);
    }
}

export default new SplitText();
